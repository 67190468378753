import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Table, Progress } from 'antd';
import { ArrowLeft2 } from 'iconsax-react';
import ViewAmountSaved from './ViewAmountSaved';
import { viewAmount } from './allProps';
import NoSavingsYet from './NoSavingsYet';
import SavingsProgressNav from './SavingsProgressNav';
import StartSavingModal from '../../../../components/shared/modal/StartSavingsModal';
import SavingsForms from 'components/user/SavingsForm';
import { formatToNaira } from 'functions/currencyConveter';
import { RoutePaths } from 'routes/route-paths';
import { FiCreditCard } from 'react-icons/fi';
import SavingsTabs from 'components/tabs/SavingsTabs';
import { appAxios } from 'api/axios';
import { getTokenDetails } from 'functions/userSession';
import { decodeToken } from 'react-jwt';
import { TokenType } from 'types/jwt';
import OngoingItemsSelectedForSaving from './OngoingItemsSelectedForSaving';
import SavedItems from './SavedItems';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
// } from 'recharts';

interface SavingsData {
  key: string;
  name: string;
  maturityDate: string;
  savingsGoal: string;
  goalLevel: number;
}

const savingsData: SavingsData[] = [
  {
    key: '1',
    name: 'My new savings 2',
    maturityDate: '2 Mar, 2025',
    savingsGoal: '₦250,000.00',
    goalLevel: 100,
  },
  {
    key: '2',
    name: 'My new special 1st quarter savings',
    maturityDate: '16 April, 2025',
    savingsGoal: '₦400,000.00',
    goalLevel: 100,
  },
  {
    key: '3',
    name: 'My new savings',
    maturityDate: '25 March, 2025',
    savingsGoal: '₦150,000.00',
    goalLevel: 100,
  },
  {
    key: '4',
    name: 'My new savings 2',
    maturityDate: '2 Mar, 2025',
    savingsGoal: '₦250,000.00',
    goalLevel: 100,
  },
  {
    key: '5',
    name: 'My new special 1st quarter savings',
    maturityDate: '16 April, 2025',
    savingsGoal: '₦400,000.00',
    goalLevel: 100,
  },
  {
    key: '6',
    name: 'My new savings',
    maturityDate: '25 March, 2025',
    savingsGoal: '₦150,000.00',
    goalLevel: 100,
  },
];

const columns = [
  { title: 'Savings Name', dataIndex: 'name', key: 'name' },
  { title: 'Maturity Date', dataIndex: 'maturityDate', key: 'maturityDate' },
  { title: 'Savings Goal', dataIndex: 'savingsGoal', key: 'savingsGoal' },
  {
    title: 'Goal Level',
    dataIndex: 'goalLevel',
    key: 'goalLevel',
    render: (goalLevel: number) => (
      <Progress percent={goalLevel} strokeColor="#4CAF50" showInfo={true} />
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: () => (
      <a className="text-[#EFA005] font-medium hover:underline">View Details</a>
    ),
  },
];

const savingsHistoryData = [
  { date: '11', amount: 20000 },
  { date: '12', amount: 45000 },
  { date: '13', amount: 30000 },
  { date: '14', amount: 15000 },
  { date: '15', amount: 35000 },
  { date: '16', amount: 10000 },
  { date: '17', amount: 40000 },
  { date: '18', amount: 25000 },
  { date: '19', amount: 15000 },
  { date: '20', amount: 20000 },
  { date: '21', amount: 10000 },
  { date: '22', amount: 30000 },
  { date: '23', amount: 45000 },
  { date: '24', amount: 15000 },
  { date: '25', amount: 50000 },
];

function HowJomptVaultWorks() {
  const token = getTokenDetails();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const decodedToken = decodeToken<TokenType>(token!);
  const customerId = decodedToken?.customerId;

  const [allSavings, setAllSavings] = useState<[]>([]);
  const [totalSavingsBalance, setTotalSavingsBalance] = useState();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showSavingsForm, setShowSavingsForm] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState('ongoing');

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous route
  };

  const goToCreateSavings = () => {
    navigate(RoutePaths.USER_CREATE_SAVINGS_JOMPVAULT);
  };

  const handleStartSavings = () => {
    setIsModalOpen(false); // Close the modal
    setShowSavingsForm(true); // Show the SavingsForms component
  };

  // Prepare data for the chart
  const chartData: ChartData<'bar'> = {
    labels: savingsHistoryData.map((item) => item.date),
    datasets: [
      {
        label: 'Amount Saved',
        data: savingsHistoryData.map((item) => item.amount),
        backgroundColor: '#31005C',
        borderColor: '#31005C',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    const getAllSavings = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(
          `get-customer-savings?CustomerId=${customerId}`
        );
        if (data && data.statusCode === 200) {
          setAllSavings(data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getAllSavings();
  }, [activeTab, activeTab === 'ongoing']);

  useEffect(() => {
    const getTotalSavingsBalance = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.post(
          `get-total-savings-balance?customerId=${customerId}`
        );
        if (data && data.statusCode === 200) {
          console.log(data, 'data');
          setTotalSavingsBalance(data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getTotalSavingsBalance();
  }, []);

  return (
    // <div className="container mx-auto mt-5 mb-28">
    //   <div className="flex flex-col gap-y-4">
    // <button
    //   onClick={handleGoBack}
    //   className="flex items-center gap-2 text-[#EFA005]"
    // >
    //   <ArrowLeft2 />
    //   Back
    // </button>
    //     {showSavingsForm ? (
    //       <SavingsForms />
    //     ) : (
    //       //when the SavingsForms displays everything here is hidden
    //       <>
    //         <div className="flex flex-col md:flex-row lg:flex-row justify-between items-center ">
    //           <div className="mb-4 lg:mb-0">
    //             <h1 className="text-2xl font-semibold">Savings</h1>
    //             <p>Let's see how well you are doing with Jomp Savings</p>
    //           </div>

    //           <button
    //             className="bg-[#EFA005] text-white rounded-md px-4 py-2 "
    //             onClick={() => setIsModalOpen(true)}
    //           >
    //             Start New Savings
    //           </button>
    //         </div>

    //         {/* <ViewAmountSaved viewAmount={viewAmount} /> */}

    //         <div className="mt-2 mb-4 bg-white py-6">
    //           <div className="flex flex-col lg:flex-row lg:gap-10 items-center lg:pb-0 pb-4 px-4">
    //             <div className="flex lg:gap-10 lg:flex-row flex-col lg:items-center">
    //               <div>
    //                 <figure className="rounded-md m-x-4 ml-4 h-[100%]">
    //                   <img src="/svgs/Info.svg" alt="" />
    //                 </figure>
    //               </div>
    //               <div className="flex-1 my-4">
    //                 <h2 className="font-bold mb-2 ">How Jomp Savings Works</h2>
    //                 <p className="text-sm">
    //                   Planning for something special in the next month or two ?
    //                   Whether it's a grooming appointment, a saloon, or car
    //                   repairs, JompVault helps you save specifically for these
    //                   short-term goals
    //                 </p>
    //               </div>
    //             </div>
    //             {/* <Link
    //               to="/"
    //               className="border-2 border-solid border-[#EFA005] px-4 py-1 rounded-md shrink-0 mb-[-20px] lg:mb-0"
    //             >
    //               Learn More
    //             </Link> */}
    //           </div>
    //         </div>

    //         <div className="">
    //           <SavingsProgressNav
    //             activeTab={activeTab}
    //             onTabChange={handleTabChange}
    //           />
    // {activeTab === 'Ongoing' && allSavings.length > 0 ? (
    //   <SavedItems allSavings={allSavings} />
    // ) : (
    //   <NoSavingsYet />
    // )}
    //         </div>
    //       </>
    //     )}
    //   </div>
    //   <StartSavingModal
    //     isOpen={isModalOpen}
    //     onClose={() => setIsModalOpen(false)}
    //     title=""
    //     subtitle=""
    //     btnText="Proceed"
    //     content={
    //       <p>
    //         {/* Your email address has been successfully verified, proceed to
    //         complete your onboarding details. */}
    //       </p>
    //     }
    //     onAction={handleStartSavings}
    //   />
    // </div>min-h-screen
    <div className="p-6 bg-[#F8F9FC] ">
      <div className="flex justify-center items-center mx-auto h-screen">
        <h1 className="text-4xl font-bold">Coming Soon...</h1>
      </div>
      {/* <div className="flex flex-col gap-y-4">
        <button
          onClick={handleGoBack}
          className="flex items-center gap-2 text-[#EFA005]"
        >
          <ArrowLeft2 />
          Back
        </button>

        <div className="flex flex-col md:flex-row lg:flex-row justify-between items-center ">
          <div className="mb-4 lg:mb-0">
            <h1 className="text-2xl font-semibold">Savings</h1>
            <p>
              Reach your unique individual saving goals for 45 to 90 days with
              25% interest P.A
            </p>
          </div>

          <button
            className="bg-[#EFA005] text-white rounded-md px-4 py-2 "
            onClick={goToCreateSavings}
          >
            Create Savings
          </button>
        </div>
      </div> */}

      {/* Header */}
      {/* <div>
        <h1 className="text-2xl font-semibold text-gray-900">Savings</h1>
        <p className="text-gray-600">
          Reach your unique individual saving goals for 45 to 90 days with 25%
          interest P.A
        </p>
      </div> */}

      {/* Cards */}
      {/* <div className="grid grid-cols-2 gap-4 mt-6">
        <div className="flex gap-3 flex-col">
          <div className="bg-gradient-to-r h-1/2 from-purple-400 to-indigo-600 p-6 rounded-lg text-white">
            <h3 className="text-lg font-medium">Total Savings Balance</h3>
            
            <p className="text-2xl font-semibold">
              {formatToNaira(totalSavingsBalance) || 0}
            </p>
          </div>
          <div className="bg-[#EFA005] h-1/2 p-6 rounded-lg text-white">
            <p className="text-sm">
              This is all you have earned based on all your savings. To earn
              more, keep saving!
            </p>
            <p className="text-2xl font-semibold">₦0.00</p>
          </div>
        </div>
        <div className="bg-white px-6 py-3 rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Savings History</h3>
            <button className="border px-3 py-1 rounded-md text-gray-600">
              This Month
            </button>
          </div>
          
          <Bar data={chartData} options={chartOptions} />
        </div>
      </div> */}

      {/* Transactions */}
      {/* <div className="bg-white p-6 mt-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-[#595BD426]">
              <FiCreditCard className="text-lg text-gray-600" />
            </div>
            <div className="flex flex-col">
              <h3 className="text-lg font-semibold">Transactions</h3>
              <span className="text-gray-500 text-xs">
                View all transactions
              </span>
            </div>
          </div>

          
          <Link
            to={`${RoutePaths.USER_SAVINGS_TRANSACTION}`}
            className="text-[#EFA005] hover:underline"
          >
            View
          </Link>
        </div>
      </div> */}

      {/* <SavingsTabs
        allSavings={allSavings}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      /> */}
    </div>
  );
}

export default HowJomptVaultWorks;
