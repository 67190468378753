import { toast } from 'react-toastify';

type Type = 'success' | 'info' | 'warning' | 'error';
type Message = string;

export const sendFeedback = (message: Message, type?: Type) => {
  toast[type || 'info'](message, {
    toastId: 'toastId',
    autoClose: 3000,
  });
};



export const sendCatchFeedback = (data: any) => {
  // const errorData = error?.response?.data || error;

  // Check if the error response is an HTML page
  // if (
  //   typeof errorData === 'string' &&
  //   errorData.includes('System.InvalidOperationException')
  // ) {
  //   toast.error('A server error occurred. Please try again later.', {
  //     toastId: 'htmlError',
  //     autoClose: 3000,
  //   });
  //   return;
  // }
  // Check if there is a response with validation errors
  const validationErrors = data?.errors;

  if (validationErrors) {
    // Loop through the validation errors and show each one as a toast notification
    Object.keys(validationErrors).forEach((key) => {
      validationErrors[key].forEach((message: string) => {
        toast.error(message, {
          toastId: key,
          autoClose: 3000,
        });
      });
    });
  } else {
    // Fallback for other errors that don't contain validation details
    toast.error(data?.message || 'Request unsuccessful', {
      toastId: 'generalError',
      autoClose: 3000,
    });
  }
};

